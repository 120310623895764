import React from "react";  
import ReactDOM from "react-dom";  
import App from "./Home";  
import "bootstrap/dist/css/bootstrap.css"; 
import "bootstrap/dist/js/bootstrap.js";   
import "./index.css"; 
  
ReactDOM.render(  
  <React.StrictMode>  
    <App />  
  </React.StrictMode>,  
  document.getElementById("root")  
);  
 