import React from 'react';
export const DigitalIcon = class DigitalIcon extends React.Component {
  render() {
    return (
      <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
        width="56.000000pt" height="56.000000pt" viewBox="0 0 56.000000 56.000000"
        preserveAspectRatio="xMidYMid meet">

        <g transform="translate(0.000000,56.000000) scale(0.100000,-0.100000)"
          fill="#000000" stroke="none">
          <path d="M83 553 c-15 -6 -17 -49 -3 -58 20 -12 11 -55 -14 -65 -13 -4 -33
          -21 -46 -37 -26 -33 -23 -92 7 -125 18 -20 18 -20 23 8 4 25 10 29 43 32 43 4
          64 -9 69 -41 4 -20 6 -19 24 10 22 38 34 38 53 -3 14 -28 29 -44 43 -44 9 0
          38 30 38 40 0 16 -33 41 -47 36 -39 -15 -100 76 -73 109 17 21 25 19 40 -10
          16 -31 23 -31 35 -2 8 17 20 23 52 25 l41 3 26 -57 c23 -51 25 -59 11 -67 -35
          -19 -55 -52 -55 -88 0 -52 -21 -67 -59 -40 -34 25 -97 28 -127 6 -12 -8 -27
          -28 -34 -45 -9 -21 -19 -30 -36 -30 -13 0 -24 4 -24 9 0 16 -40 23 -55 11 -19
          -16 -19 -35 1 -55 15 -14 18 -14 40 0 31 20 63 19 71 -2 3 -10 13 -26 22 -36
          15 -18 16 -18 21 5 5 20 12 23 55 23 45 0 50 -2 53 -23 3 -23 4 -23 23 -6 10
          9 19 23 19 31 0 27 45 0 64 -39 14 -27 54 -31 69 -6 14 22 -7 48 -40 48 -22 0
          -83 25 -83 34 0 3 -3 11 -6 19 -3 10 3 20 16 27 18 10 24 8 36 -7 13 -17 13
          -17 14 4 0 25 27 43 64 43 30 0 57 -25 50 -45 -4 -8 -2 -15 3 -15 15 0 43 57
          43 88 0 39 -41 88 -80 97 -43 9 -65 54 -50 100 11 35 12 35 41 20 16 -9 29
          -22 29 -29 0 -7 6 -19 14 -25 19 -16 56 3 56 28 0 24 -25 45 -45 38 -22 -9
          -83 20 -90 42 -3 11 -20 32 -38 47 -55 46 -133 21 -157 -51 -16 -48 -53 -70
          -91 -55 -32 14 -41 53 -14 64 18 7 20 37 3 54 -13 13 -24 14 -45 5z m276 -52
          c13 -24 -8 -55 -34 -50 -28 6 -42 34 -26 53 18 22 48 20 60 -3z m-229 -116
          c20 -25 8 -50 -25 -50 -31 0 -43 20 -31 51 7 18 40 18 56 -1z m351 -127 c10
          -18 8 -24 -10 -39 -25 -21 -48 -16 -56 12 -14 44 44 67 66 27z m-233 -100 c17
          -17 15 -43 -4 -59 -31 -26 -70 25 -44 56 15 18 32 19 48 3z"/>
          <path d="M310 485 c0 -10 7 -15 18 -13 21 4 19 22 -2 26 -10 2 -16 -3 -16 -13z" />
          <path d="M87 374 c-9 -10 2 -24 19 -24 8 0 14 7 14 15 0 15 -21 21 -33 9z" />
          <path d="M434 249 c-10 -17 13 -36 27 -22 12 12 4 33 -11 33 -5 0 -12 -5 -16
-11z"/>
          <path d="M210 129 c0 -24 23 -21 28 4 2 10 -3 17 -12 17 -10 0 -16 -9 -16 -21z" />
          <path d="M290 387 c0 -28 32 -42 61 -27 30 17 16 45 -24 48 -33 3 -37 1 -37
-21z"/>
          <path d="M70 266 c0 -30 14 -40 46 -32 17 5 24 13 24 31 0 22 -4 25 -35 25
-30 0 -35 -3 -35 -24z"/>
          <path d="M414 156 c-12 -30 4 -47 38 -44 28 2 33 7 33 28 0 21 -5 26 -33 28
-22 2 -34 -2 -38 -12z"/>
          <path d="M197 54 c-4 -4 -7 -18 -7 -31 0 -20 5 -23 36 -23 33 0 35 2 32 27 -2
21 -9 29 -28 31 -14 2 -29 0 -33 -4z"/>
        </g>
      </svg>
    );
  }
};
export const GeoIcon = class GeoIcon extends React.Component {
  render() {
    return (
      <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
        width="63.000000pt" height="63.000000pt" viewBox="0 0 63.000000 63.000000"
        preserveAspectRatio="xMidYMid meet">

        <g transform="translate(0.000000,63.000000) scale(0.100000,-0.100000)"
          fill="#000000" stroke="none">
          <path d="M283 611 c-23 -14 -34 -30 -39 -56 -6 -32 -2 -44 32 -93 22 -30 44
-57 50 -59 17 -6 84 106 84 139 0 33 -36 75 -70 83 -16 3 -37 -2 -57 -14z m81
-37 c21 -20 20 -43 -1 -66 -23 -26 -59 -23 -79 7 -15 23 -15 27 0 50 19 29 56
33 80 9z"/>
          <path d="M32 528 c-8 -8 -12 -58 -12 -160 0 -135 2 -148 19 -158 40 -21 41
-15 41 160 0 157 -1 170 -18 170 -10 0 -23 -5 -30 -12z m28 -28 c0 -11 -4 -20
-10 -20 -5 0 -10 9 -10 20 0 11 5 20 10 20 6 0 10 -9 10 -20z m0 -130 c0 -22
-4 -40 -10 -40 -5 0 -10 18 -10 40 0 22 5 40 10 40 6 0 10 -18 10 -40z m0
-120 c0 -11 -4 -20 -10 -20 -5 0 -10 9 -10 20 0 11 5 20 10 20 6 0 10 -9 10
-20z"/>
          <path d="M110 380 c0 -88 -1 -163 -2 -167 -2 -5 25 -9 60 -11 l63 -3 -7 26
c-8 31 10 55 40 55 19 0 21 5 18 39 -2 22 1 47 6 57 8 14 2 29 -24 67 -19 27
-37 60 -40 73 -6 22 -12 24 -60 24 l-54 0 0 -160z m90 35 c0 -32 -2 -35 -30
-35 -28 0 -30 3 -30 35 0 32 2 35 30 35 28 0 30 -3 30 -35z m0 -145 c0 -27 -3
-30 -30 -30 -27 0 -30 3 -30 30 0 27 3 30 30 30 27 0 30 -3 30 -30z"/>
          <path d="M430 522 c0 -9 -15 -40 -34 -68 -30 -44 -33 -56 -29 -97 5 -45 7 -47
47 -57 60 -15 70 -23 76 -61 4 -28 9 -34 30 -34 l25 0 3 168 2 167 -60 0 c-51
0 -60 -3 -60 -18z m95 -122 c-3 -5 -33 -10 -66 -10 -33 0 -59 4 -59 10 0 6 29
10 66 10 41 0 63 -4 59 -10z m0 -40 c-3 -5 -33 -10 -66 -10 -33 0 -59 4 -59
10 0 6 29 10 66 10 41 0 63 -4 59 -10z m5 -40 c0 -6 -28 -10 -65 -10 -37 0
-65 4 -65 10 0 6 28 10 65 10 37 0 65 -4 65 -10z"/>
          <path d="M570 371 c0 -179 2 -188 43 -158 16 11 17 30 15 167 l-3 155 -27 3
-28 3 0 -170z m40 139 c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10 10 0 6 5 10 10 10
6 0 10 -4 10 -10z m0 -40 c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10 10 0 6 5 10 10
10 6 0 10 -4 10 -10z m0 -200 c0 -22 -4 -40 -10 -40 -5 0 -10 18 -10 40 0 22
5 40 10 40 6 0 10 -18 10 -40z"/>
          <path d="M310 306 l0 -76 -26 16 c-33 22 -49 8 -34 -30 5 -15 10 -44 10 -64 0
-21 7 -47 15 -59 13 -18 13 -26 4 -41 -7 -11 -9 -27 -6 -36 5 -13 22 -16 98
-16 l92 0 -6 39 c-2 22 -1 43 5 49 5 5 8 47 6 93 -2 67 -6 84 -18 84 -8 0 -21
4 -27 8 -7 5 -17 6 -22 2 -5 -3 -13 0 -16 6 -4 5 -13 8 -20 5 -10 -4 -15 8
-17 42 -2 35 -7 48 -20 50 -16 3 -18 -6 -18 -72z"/>
          <path d="M505 150 c3 -5 33 -10 66 -10 33 0 59 4 59 10 0 6 -29 10 -66 10 -41
0 -63 -4 -59 -10z"/>
          <path d="M10 130 c0 -18 7 -20 85 -20 78 0 85 2 85 20 0 18 -7 20 -85 20 -78
0 -85 -2 -85 -20z"/>
          <path d="M500 110 c0 -5 20 -10 45 -10 25 0 45 5 45 10 0 6 -20 10 -45 10 -25
0 -45 -4 -45 -10z"/>
          <path d="M610 110 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"/>
          <path d="M0 70 c0 -6 38 -10 95 -10 57 0 95 4 95 10 0 6 -38 10 -95 10 -57 0
-95 -4 -95 -10z"/>
          <path d="M505 70 c-4 -6 18 -10 59 -10 37 0 66 4 66 10 0 6 -26 10 -59 10 -33
0 -63 -4 -66 -10z"/>
          <path d="M0 30 c0 -6 32 -10 75 -10 43 0 75 4 75 10 0 6 -32 10 -75 10 -43 0
-75 -4 -75 -10z"/>
          <path d="M170 30 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"/>
          <path d="M505 30 c-3 -5 1 -10 10 -10 9 0 13 5 10 10 -3 6 -8 10 -10 10 -2 0
-7 -4 -10 -10z"/>
          <path d="M550 30 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2 0 -4
-4 -4 -10z"/>
          <path d="M590 30 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"/>
        </g>
      </svg>
    );
  }
};
export const UnityIcon = class UnityIcon extends React.Component {
  render() {
    return (
      <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
        width="50.000000pt" height="56.000000pt" viewBox="0 0 50.000000 56.000000"
        preserveAspectRatio="xMidYMid meet">

        <g transform="translate(0.000000,56.000000) scale(0.100000,-0.100000)"
          fill="#000000" stroke="none">
          <path d="M113 492 l-113 -64 0 -129 c0 -71 2 -129 5 -129 3 0 23 11 45 25 38
24 40 28 40 75 0 28 3 50 8 50 4 0 31 -14 60 -30 l52 -30 0 -65 c0 -36 -3 -65
-6 -65 -3 0 -25 11 -48 25 l-43 25 -41 -22 c-23 -13 -42 -25 -42 -28 1 -3 50
-33 111 -68 l110 -63 110 63 c60 35 109 65 109 68 0 3 -18 15 -40 28 l-40 23
-43 -26 c-24 -14 -46 -25 -48 -25 -5 0 -2 130 3 134 11 10 96 56 101 56 4 0 7
-24 7 -53 0 -56 -1 -55 83 -95 4 -2 7 55 7 126 l0 130 -82 47 c-46 26 -98 56
-115 67 l-33 20 0 -51 c0 -49 1 -51 46 -77 51 -30 50 -34 -22 -74 l-41 -22
-39 20 c-21 11 -48 27 -58 35 -19 14 -18 16 23 36 48 25 53 34 49 88 l-3 39
-112 -64z"/>
        </g>
      </svg>
    );
  }
};
export const CyberIcon = class CyberIcon extends React.Component {
  render() {
    return (
      <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
        width="49.000000pt" height="49.000000pt" viewBox="0 0 49.000000 49.000000"
        preserveAspectRatio="xMidYMid meet">

        <g transform="translate(0.000000,49.000000) scale(0.100000,-0.100000)"
          fill="#000000" stroke="none">
          <path d="M165 471 c-48 -22 -89 -67 -105 -117 -17 -50 -8 -129 19 -165 l22
-29 -39 0 c-53 0 -64 -16 -60 -92 l3 -63 240 0 240 0 3 63 c4 76 -7 92 -60 92
l-39 0 23 30 c32 43 37 134 10 186 -51 100 -159 140 -257 95z m152 -37 c69
-34 107 -140 72 -207 -17 -33 -51 -67 -66 -67 -16 0 -18 75 -3 85 18 11 11 38
-17 67 -26 27 -26 28 -8 48 15 17 16 22 4 37 -37 50 -60 54 -95 16 -23 -25
-23 -28 -9 -50 15 -22 14 -26 -9 -54 -28 -33 -31 -44 -16 -54 6 -3 10 -26 10
-51 0 -50 -15 -57 -47 -21 -32 35 -43 62 -43 109 0 117 120 192 227 142z m-39
-51 c2 -9 -8 -13 -32 -13 -34 0 -44 8 -29 24 11 11 56 2 61 -11z m-18 -215 c0
-5 -7 -8 -15 -8 -17 0 -18 2 -9 25 5 13 8 14 15 3 5 -7 9 -16 9 -20z m-145
-38 c-5 -16 -2 -20 19 -20 16 0 26 6 26 15 0 8 5 15 10 15 11 0 7 -23 -16 -95
-8 -28 -12 -33 -13 -17 -1 31 -52 31 -75 -1 l-17 -22 6 22 c5 18 1 22 -22 26
-27 4 -26 4 5 6 17 0 32 3 32 5 0 2 3 11 6 20 5 12 0 16 -18 16 -13 0 -29 -5
-36 -12 -9 -9 -12 -9 -12 3 0 9 10 24 22 35 19 17 21 17 15 2 -9 -26 39 -24
53 2 6 11 13 20 16 20 3 0 2 -9 -1 -20z m314 -20 c15 0 19 4 14 18 -6 15 -4
15 15 -2 12 -11 22 -26 22 -35 0 -12 -3 -12 -12 -3 -11 11 -58 17 -58 8 0 -22
17 -36 44 -37 30 -2 30 -2 3 -6 -23 -4 -27 -8 -22 -26 l6 -22 -17 23 c-11 14
-27 22 -46 22 -25 0 -28 -3 -23 -20 3 -11 2 -20 -2 -20 -5 0 -16 29 -25 65 -8
36 -11 65 -7 65 5 0 9 -7 9 -15 0 -9 10 -15 26 -15 21 0 24 3 19 23 l-6 22 20
-22 c10 -13 28 -23 40 -23z m-182 -42 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3
4 -12 1 -19z"/>
          <path d="M116 338 c-9 -36 -7 -86 5 -93 7 -5 8 4 4 27 -3 18 -2 46 4 61 6 15
7 27 2 27 -5 0 -11 -10 -15 -22z"/>
          <path d="M362 330 c6 -17 8 -44 4 -61 -3 -19 -2 -28 4 -24 11 7 14 74 4 99
-11 30 -23 16 -12 -14z"/>
          <path d="M90 80 c-10 -18 -8 -20 25 -20 28 0 35 4 35 20 0 15 -7 20 -25 20
-14 0 -29 -8 -35 -20z"/>
          <path d="M342 83 c2 -12 14 -19 36 -21 31 -3 33 -2 22 17 -14 27 -63 30 -58 4z" />
        </g>
      </svg>
    );
  }
};
export const ButtonIcon = class ButtonIcon extends React.Component {
  render() {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width="260" height="80" viewBox="0 0 260 93">
        <g fill="#e4811d" fill-rule="evenodd">
          <path class="cls-1" d="M19.091,4.847S71.611,0.364,125.455,0C183.5-.392,243.192,3.332,243.192,3.332a16.664,16.664,0,0,1,16.667,16.661V70.068c0,9.2-8.068,11.814-16.667,13.329,0,0-58.268,8.663-116.828,9.6-54.561.876-109.7-6.271-109.7-6.271C8.371,85.518,4.545,79.27,4.545,70.068L0,19.994C0,10.792,9.886,4.847,19.091,4.847Z" />
          <text id="Request_Information" data-name="Request Information" class="cls-2" x="237.937" y="51.75" font-size="20px" fill= "#fff" text-anchor= "end" font-family= "Poppins" font-weight= "600"><tspan x="237.937">Request Information</tspan></text>
        </g>
      </svg>

    );
  }
};
export const SubmitIcon = class ButtonIcon extends React.Component {
  render() {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width="260" height="70" viewBox="0 0 260 93">
        <g fill="#e4811d" fill-rule="evenodd">
          <path class="cls-1" d="M19.091,4.847S71.611,0.364,125.455,0C183.5-.392,243.192,3.332,243.192,3.332a16.664,16.664,0,0,1,16.667,16.661V70.068c0,9.2-8.068,11.814-16.667,13.329,0,0-58.268,8.663-116.828,9.6-54.561.876-109.7-6.271-109.7-6.271C8.371,85.518,4.545,79.27,4.545,70.068L0,19.994C0,10.792,9.886,4.847,19.091,4.847Z" />
          <text id="Request_Information" data-name="Request Information" class="cls-2" x="237.937" y="51.75" font-size="20px" fill= "#fff" text-anchor= "end" font-family= "Poppins" font-weight= "600"><tspan x="166.937">Submit</tspan></text>
        </g>
      </svg>

    );
  }
}